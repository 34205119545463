import React, { useMemo } from 'react';
import { getDocumentFields } from 'utils/helper';
import ContentfulParser from 'components/BlogCommon/Article/ContentfulParser';
import Svg from 'UI/components/Svg';
import styles from './styles.module.scss';

const Cards = ({ cards }) => {
  const list = useMemo(() => cards
    ?.map((card) => {
      const { text, title, contentList } = getDocumentFields(
        card,
        [
          'text', 'title', 'contentList'],
      );

      return {
        text,
        title,
        icon: contentList?.[0],
      };
    }), [cards]);

  return (
    <div className={styles['cards-list']}>
      {list?.map((item, i) => (
        <article
          key={i}
          className={styles.card}
        >
          {item?.icon && (
            <Svg
              type={item.icon}
              className={styles['card-icon']}
            />
          )}
          {item?.title && (
            <h4 className={styles['card-title']}>{item.title}</h4>
          )}
          {item?.text && (
            <div className={styles['card-text']}>
              <ContentfulParser document={item.text} />
            </div>
          )}
        </article>
      ))}
    </div>
  );
};

export default Cards;
