import dynamic from 'next/dynamic';
import get from 'lodash/get';
import { getDocumentFields } from 'utils/helper';
import SectionTitle from 'UI/components/SectionTitle';
import Cards from './Cards';
import styles from './styles.module.scss';

const ScrollingGallery = dynamic(() => import('./ScrollingGallery'));

const PhotoGallery = ({ sectionData }) => {
  const { contentModules, title } = getDocumentFields(sectionData, ['contentModules', 'title']);
  const { images: photos } = getDocumentFields(get(contentModules, '[0]', null), ['contentModules', 'images']);
  const { contentModules: cards } = getDocumentFields(get(contentModules, '[1]', null), ['contentModules']);

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <SectionTitle
          title={title}
          className={styles.title}
        />
        <Cards cards={cards} />
        <ScrollingGallery photos={photos} />
      </div>
    </section>
  );
};

export default PhotoGallery;
